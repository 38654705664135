import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

import MainLayout from "../layout";

import wavingHand from "../assets/images/waving-hand.png";
import buttonImg from "../assets/images/button.png";

const StyledIntroductionTitle = styled.div({
  marginTop: 113,
  marginBottom: 36,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  h1: {
    marginRight: 8,
    color: "#031631",
    fontSize: 28,
    fontWeight: "bold",
    letterSpacing: -0.98,
    lineHeight: "36px"
  },

  img: {
    width: 34.6,
    height: 34
  }
});

const StyledIntroductionParagraph = styled.p({
  width: 549,
  height: 66,
  fontSize: 28,
  fontWeight: 500,
  letterSpacing: -0.98,
  lineHeight: "36px",
  textAlign: "center",
  color: "#011433",
  marginBottom: 40,

  span: {
    color: "#0b58da"
  }
});

const StyledNextButton = styled.div({
  backgroundImage: `url(${buttonImg})`,
  width: 262,
  height: 105,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexFlow: "column",

  span: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: "26px",
    letterSpacing: -0.7,
    textAlign: "center",
    color: "#ffffff"
  },
  ".arrow-down": {
    width: 0,
    height: 0,
    borderLeft: "10px solid transparent",
    borderRight: "10px solid transparent",

    borderTop: "10px solid #fff",
    marginTop: 8
  }
});

const Introduction = ({ navigateToNext }) => (
  <MainLayout showKidsFooterImage={true}>
    <StyledIntroductionTitle>
      <h1>Hello!</h1>
      <img src={wavingHand} alt="hello icon" />
    </StyledIntroductionTitle>

    <StyledIntroductionParagraph>
      <span>Wuzzuf</span> will help you to know your career interests!
    </StyledIntroductionParagraph>

    <StyledNextButton onClick={navigateToNext}>
      <span>Enter my name</span>
      <span className="arrow-down"></span>
    </StyledNextButton>
  </MainLayout>
);

Introduction.propTypes = {
  navigateToNext: PropTypes.func
};

export default Introduction;
