import React, { useState } from 'react';
import styled from '@emotion/styled';

import { results } from '../helper';

import yesEmoji from '../assets/images/yes-emoji.png';
import ProfileResults from './profile-results';
import sometimesEmoji from '../assets/images/sometimes-emoji.png';
import noEmoji from '../assets/images/no-emoji.png';

const Card = styled.div({
    borderBottom: '1px solid #E3E3E3',
    paddingBottom: 24,
    marginBottom: 24,
    '&:last-of-type': {
        borderBottom: 0,
        paddingBottom: 0,
        marginBottom: 0
    }
});

const StyledKidImg = styled.img({
    width: 50,
    height: 50,
    borderRadius: '50%',
    marginRight: 8
});

const StyledBasicInfoAndImgWrapper = styled.div({
    display: 'flex',
    alignItems: 'center',
    position: 'relative'
});

const StyledBasicInfo = styled.div({
    display: 'flex',
    flexFlow: 'column',

    strong: {
        fontSize: 16,
        fontWeight: 'bold',
        letterSpacing: -0.56,
        lineHeight: '21px'
    },

    span: {
        opacity: 0.8,
        fontSize: 14,
        fontWeight: 500,
        lineHeight: '19px'
    }
});

const StyledResultsVisiblityTitle = styled.button({
    color: '#153be6',
    position: 'absolute',
    bottom: -14,
    right: 0,
    background: 'transparent',
    border: 0,
    outline: 0,
    cursor: 'pointer',
    fontSize: 14
});

const StyledProfileResultsHeaderWrapper = styled.div({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: 'white',
    marginTop: 32,
    margin: '32px -16px 0 -16px'
});

const StyledProfileResultsHeader = styled.h3({
    color: '#011433',
    fontSize: 24,
    lineHeight: '24px',
    fontWeight: 600
});

const StyledHeaderIconsDefinition = styled.div({
    display: 'flex',
    alignItems: 'center',
    borderRadius: 19,
    background: 'rgba(0,0,0,0.03)',
    padding: '8px 16px',
    img: {
        maxWidth: 24,
        maxHeight: 24,
        margin: '0 8px'
    },
    span: {
        color: '#000000',
        fontSize: 12.8,
        lineHeight: '18px'
    }
});

const ProfileCard = ({ profile }) => {
    const [showResults, setResultsShow] = useState(false);

    return (
        <Card>
            <StyledBasicInfoAndImgWrapper>
                {profile.imageBase64 ? (
                    <StyledKidImg src={`data:image/jpeg;base64,${profile.imageBase64}`} alt="kid" />
                ) : (
                    <StyledKidImg src={yesEmoji} alt="kid" />
                )}
                <StyledBasicInfo>
                    <strong>{profile.kidName}</strong>
                    <span>
                        {profile.kidAge} years old, {profile.kidSchool}
                    </span>
                </StyledBasicInfo>
                <StyledResultsVisiblityTitle onClick={() => setResultsShow(!showResults)}>
                    {showResults ? 'Hide Results' : 'Show Results'}
                </StyledResultsVisiblityTitle>
            </StyledBasicInfoAndImgWrapper>
            {showResults && (
                <div style={{ background: 'rgb(238, 250, 250)', padding: '0px 16px 4px' }}>
                    <StyledProfileResultsHeaderWrapper>
                        <StyledProfileResultsHeader>Results</StyledProfileResultsHeader>
                        <StyledHeaderIconsDefinition>
                            <img src={yesEmoji} alt="love it" />
                            <span>Love it</span>
                            <img src={sometimesEmoji} alt="like it" />
                            <span>Like it</span>
                            <img src={noEmoji} alt="hate it" />
                            <span>Hate it</span>
                        </StyledHeaderIconsDefinition>
                    </StyledProfileResultsHeaderWrapper>
                    <ProfileResults results={results(profile.questions)} />
                </div>
            )}
        </Card>
    );
};

export default ProfileCard;
