import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import Introduction from './components/introduction';
import ProfileBasicInfoEntry from './components/profile-basic-info-entry';
import Question from './components/question';
import TakePhoto from './components/take-photo';
import TestCompletedCard from './components/test-completed-card';
import Profile from './components/profile';

class MainPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            questions: props.questions,
            profileBasicInfoQuestions: props.profileBasicInfoQuestions,
            currentProfileQuestionIndex: 0,
            currentQuestionIndex: 0,
            pageType: 'introduction',
            score: 0,
            kidImage: null,
            kidName: '',
            kidAge: '',
            kidSchool: '',
            kidGrade: ''
        };
    }

    handleGoBackClick = () => {
        const { currentQuestionIndex } = this.state;
        const isFirstQuestion = currentQuestionIndex === 0;
        if (!isFirstQuestion) {
            this.setState({
                currentQuestionIndex: currentQuestionIndex - 1
            });
        } else {
            this.setState({ pageType: 'profileBasicInfoQuestions' });
        }
    };

    getCurrentQuestion = () => {
        const { questions, currentQuestionIndex } = this.state;
        return questions[currentQuestionIndex];
    };

    getCurrentProfileQuestion = () => {
        const { profileBasicInfoQuestions, currentProfileQuestionIndex } = this.state;
        return profileBasicInfoQuestions[currentProfileQuestionIndex];
    };

    handleChangeQuestionAnswer = (answer, answerScore) => {
        const { questions, currentQuestionIndex, score } = this.state;
        const question = this.getCurrentQuestion();
        const canGoNext = currentQuestionIndex < questions.length - 1;
        const canIncreaseScore = !question.answer;
        const isLastQuestion = currentQuestionIndex === questions.length - 1;
        const scoreIncreasingPercentage = 100 / questions.length;

        const newQuestions = [
            ...questions.slice(0, currentQuestionIndex),
            {
                ...question,
                answer,
                score: answerScore
            },
            ...questions.slice(currentQuestionIndex + 1)
        ];
        this.setState({
            questions: newQuestions,
            ...(canIncreaseScore && { score: score + scoreIncreasingPercentage }),
            ...(canGoNext && {
                currentQuestionIndex: currentQuestionIndex + 1
            }),
            ...(isLastQuestion && { pageType: 'takePhoto' })
        });
    };

    handleChangeProfileQuestionAnswer = (answer) => {
        const { profileBasicInfoQuestions, currentProfileQuestionIndex } = this.state;
        const question = this.getCurrentProfileQuestion();

        const newQuestions = [
            ...profileBasicInfoQuestions.slice(0, currentProfileQuestionIndex),
            {
                ...question,
                answer
            },
            ...profileBasicInfoQuestions.slice(currentProfileQuestionIndex + 1)
        ];

        this.setState({
            profileBasicInfoQuestions: newQuestions,
            [question.key]: answer
        });
    };

    goToNextProfileQuestion = () => {
        const { currentProfileQuestionIndex, profileBasicInfoQuestions } = this.state;
        const isLastQuestion = currentProfileQuestionIndex === profileBasicInfoQuestions.length - 1;

        if (isLastQuestion) {
            this.setState({ pageType: 'questions' });
        } else {
            this.setState({
                currentProfileQuestionIndex: currentProfileQuestionIndex + 1
            });
        }
    };

    goToBackProfileQuestion = () => {
        const { currentProfileQuestionIndex } = this.state;
        const isFristQuestion = currentProfileQuestionIndex === 0;

        if (isFristQuestion) {
            this.setState({ pageType: 'introduction' });
        } else {
            this.setState({
                currentProfileQuestionIndex: currentProfileQuestionIndex - 1
            });
        }
    };

    goToBackQuestion = () => {
        this.setState({ pageType: 'questions' });
    };

    handleImageChange = (dataUri) => {
        const { kidAge, kidSchool, kidGrade, kidName, questions } = this.state;
        this.setState({
            kidImage: dataUri,
            pageType: 'testCompletedCard'
        });

        axios.post('/survey', {
            kidName,
            kidAge,
            kidGrade,
            kidSchool,
            questions,
            dataUri
        });
    };

    handleGoToProfile = () => {
        this.setState({ pageType: 'profile' });
    };

    handleStartNewTest = () => {
        this.setState({
            questions: this.props.questions,
            profileBasicInfoQuestions: this.props.profileBasicInfoQuestions,
            currentProfileQuestionIndex: 0,
            currentQuestionIndex: 0,
            pageType: 'introduction',
            score: 0,
            kidImage: null,
            kidName: '',
            kidAge: '',
            kidSchool: '',
            kidGrade: ''
        });
    };

    render() {
        const {
            state: { pageType, score, kidImage, kidName, kidAge, kidSchool, questions },
            getCurrentQuestion,
            getCurrentProfileQuestion,
            handleGoBackClick,
            handleChangeQuestionAnswer,
            handleChangeProfileQuestionAnswer,
            goToNextProfileQuestion,
            goToBackProfileQuestion,
            handleImageChange,
            goToBackQuestion,
            handleGoToProfile,
            handleStartNewTest
        } = this;

        const currentQuestion = getCurrentQuestion();
        const currentProfileQuestion = getCurrentProfileQuestion();

        if (pageType === 'introduction') {
            return <Introduction navigateToNext={() => this.setState({ pageType: 'profileBasicInfoQuestions' })} />;
        }

        if (pageType === 'profileBasicInfoQuestions') {
            return (
                <ProfileBasicInfoEntry
                    navigateToNext={goToNextProfileQuestion}
                    navigateToBack={goToBackProfileQuestion}
                    currentQuestion={currentProfileQuestion}
                    handleChangeProfileQuestionAnswer={handleChangeProfileQuestionAnswer}
                />
            );
        }

        if (pageType === 'questions') {
            return (
                <Question
                    {...{
                        handleGoBackClick,
                        currentQuestion,
                        handleChangeQuestionAnswer,
                        score
                    }}
                />
            );
        }

        if (pageType === 'takePhoto') {
            return <TakePhoto handleImageChange={handleImageChange} handleGoBackClick={goToBackQuestion} />;
        }

        if (pageType === 'testCompletedCard') {
            return <TestCompletedCard kidImage={kidImage} name={kidName} handleGoToProfile={handleGoToProfile} />;
        }

        if (pageType === 'profile') {
            return (
                <Profile
                    kidImage={kidImage}
                    kidName={kidName}
                    kidAge={kidAge}
                    kidSchool={kidSchool}
                    questions={questions}
                    handleStartNewTest={handleStartNewTest}
                />
            );
        }
    }
}

MainPage.propTypes = {
    questions: PropTypes.array,
    profileBasicInfoQuestions: PropTypes.array
};

export default MainPage;
