import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

import MainLayout from "../layout";
import buttonImg from "../assets/images/small-button.png";

const StyledKidImg = styled.img({
  height: 106,
  width: 106,
  borderRadius: "50%",
  marginTop: 93
});

const StyledGreetingTitle = styled.h2({
  color: "#011433",
  fontSize: 32,
  fontWeight: "bold",
  letterSpacing: -1.12,
  lineHeight: "42px",
  textAlign: "center",
  marginTop: 24
});

const StyledInstructions = styled.p({
  color: "#011433",
  fontSize: 28,
  fontWeight: 500,
  letterSpacing: -0.98,
  lineHeight: "36px",
  textAlign: "center",
  marginTop: 16,
  maxWidth: 458
});

const StyledGoNextBtn = styled.button({
  marginTop: 56,
  height: 61,
  width: 151,
  backgroundImage: `url(${buttonImg})`,
  backgroundColor: "transparent",
  border: 0,
  outline: 0,
  color: "#FFFFFF",
  fontSize: 20,
  fontWeight: 600,
  letterSpacing: -0.7,
  lineHeight: "26px"
});

const TestCompletedCard = ({ kidImage, name, handleGoToProfile }) => (
  <MainLayout>
    <StyledKidImg src={kidImage} alt="kid" />
    <StyledGreetingTitle>Great Job {name}!</StyledGreetingTitle>
    <StyledInstructions>You have completed the test</StyledInstructions>
    <StyledGoNextBtn onClick={handleGoToProfile}>Next</StyledGoNextBtn>
  </MainLayout>
);

TestCompletedCard.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  handleGoToProfile: PropTypes.func
};

export default TestCompletedCard;
