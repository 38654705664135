import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

import MainLayout from "../layout";
import buttonImg from "../assets/images/button.png";

const StyledProfileInfoEntryInput = styled.input({
  width: 365.7,
  border: "none",
  borderBottom: "solid 1px #afafaf",
  height: 40,
  outline: 0,
  marginTop: 160,
  fontSize: 20,
  letterSpacing: -0.7,
  lineHeight: "26px",
  fontWeight: 600,
  textAlign: "center",
  color: "#2244a7",

  "&:focus": {
    borderColor: "#3056b1"
  },
  "&:last-of-type": {
    marginTop: 45
  },
  "::placeholder": {
    textAlign: "center",
    fontSize: 20,
    letterApacing: -0.7,
    lineHeight: "26px",
    fontWeight: 600,
    color: "#afafaf"
  }
});

const StyledGoNextBtn = styled.div({
  backgroundImage: `url(${buttonImg})`,
  width: 262,
  height: 105,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexFlow: "column",
  marginTop: 40,

  span: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: "26px",
    letterSpacing: -0.7,
    textAlign: "center",
    color: "#ffffff"
  },
  ".arrow-down": {
    width: 0,
    height: 0,
    borderLeft: "10px solid transparent",
    borderRight: "10px solid transparent",

    borderTop: "10px solid #fff",
    marginTop: 8
  }
});

const StyledErrorAlert = styled.div({
  padding: 12,
  background: "#ffeaf2",
  color: "#92083d",
  border: "0.1px solid #92083d",
  fontSize: 18,
  width: 365.7,
  textAlign: "center",
  position: "absolute",
  top: 120
});

const Login = ({ setPageType }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [hasError, setError] = useState(false);

  const handleLogin = () => {
    const validCredentials = {
      username: "kidzania",
      password: "kidzania-admin-123"
    };

    if (
      username === validCredentials.username &&
      password === validCredentials.password
    ) {
      localStorage.setItem('isAuthenticatedAdmin', true)
      setError(false);
      setPageType("profiles");
    } else {
      setError(true);
    }
  };

  return (
    <MainLayout>
      {hasError && (
        <StyledErrorAlert>
          Please insert valid username and password
        </StyledErrorAlert>
      )}
      <StyledProfileInfoEntryInput
        value={username}
        onChange={e => setUsername(e.target.value)}
        placeholder="Type username"
      />
      <StyledProfileInfoEntryInput
        value={password}
        onChange={e => setPassword(e.target.value)}
        placeholder="Type password"
        type="password"
      />

      <StyledGoNextBtn onClick={handleLogin}>
        <span>Login</span>
        <span className="arrow-down"></span>
      </StyledGoNextBtn>
    </MainLayout>
  );
};

Login.propTypes = {
    setPageType: PropTypes.func
};

export default Login;
