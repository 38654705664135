import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

import MainLayout from "../layout";

import yesEmoji from "../assets/images/yes-emoji.png";
import trophy from "../assets/images/trophy.png";
import sometimesEmoji from "../assets/images/sometimes-emoji.png";
import noEmoji from "../assets/images/no-emoji.png";
import emptyScoreImg from "../assets/images/empty.svg";

import buttonImg from "../assets/images/small-button.png";

const StyledGoBackBtn = styled.div({
  marginTop: 47,
  marginBottom: 10,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexFlow: "column",

  ".arrow-up": {
    width: 0,
    height: 0,
    borderLeft: "9px solid transparent",
    borderRight: "9px solid transparent",
    borderBottom: "9px solid #2244a7",
    marginBottom: 7
  },

  ".text": {
    fontSize: 28,
    lineHeight: "36px",
    letterSpacing: -0.98,
    textAlign: "center",
    color: "#2244a7"
  }
});

const StyledQuestionImg = styled.img({
  width: 224,
  height: 179
});

const StyledQuestionTitle = styled.h1({
  fontSize: 32,
  letterSpacing: -1.12,
  fontWeight: "bold",
  lineHeight: "42px",
  textAlign: "center",
  color: "#011433",
  marginTop: 20,
  height: 84
});

const StyledQuestionActionsWrapper = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: 48
});

const StyledQuestionActionBtn = styled.div({
  width: 151,
  height: 61,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  margin: "0 16px",

  "&:last-of-type": {
    width: 170,

    span: {
      fontSize: 18,
      letterSpacing: -0.63,
      lineHeight: "24px"
    }
  }
});

const StyledBackgroundBtnImg = styled.img({
  position: "absolute",
  bottom: 0,
  top: "initial",
  width: "100%",
  height: 61
});

const StyledBtnEmojiImg = styled.img({
  position: "absolute",
  top: -25,
  maxWidth: 28,
  maxHeight: 28
});

const StyledQuestionActionBtnText = styled.span({
  textAlign: "center",
  color: "#ffffff",
  fontSize: 20,
  fontWeight: 600,
  letterSpacing: -0.7,
  lineHeight: "26px",
  zIndex: 1,
  height: 61,
  padding: "12px 0"
});

const StyledAnswersScoreWrapper = styled.div({
  position: "absolute",
  bottom: 115
});

const StyledScoreImg = styled.img(
  {
    position: "absolute",
    top: -24
  },
  ({ score }) => ({
    left: score ? `calc(${score}% - 21px)` : `${score}%`
  })
);

const StyledScoreBarWrapper = styled.div({
  backgroundImage: `url(${emptyScoreImg})`,
  width: 416,
  height: 41,
  display: "flex",
  alignItems: "center"
});

const StyledScoreBar = styled.div(
  {
    height: 33,
    borderRadius: 10,
    margin: "0 4px",
    background: "#fbe555"
  },
  ({ width }) => ({ width })
);

const StyledCharacterImg = styled.img({
  position: "absolute",
  bottom: 130,
  left: "-5%",
  maxHight: 198
});

const Question = ({
  score,
  handleGoBackClick,
  currentQuestion,
  handleChangeQuestionAnswer
}) => (
  <MainLayout>
    <StyledGoBackBtn onClick={handleGoBackClick}>
      <span className="arrow-up"></span>
      <span className="text">BACK</span>
    </StyledGoBackBtn>

    <StyledQuestionImg src={currentQuestion.image} alt="question" />

    <StyledQuestionTitle>{currentQuestion.title}</StyledQuestionTitle>

    <StyledQuestionActionsWrapper>
      <StyledQuestionActionBtn
        onClick={() => handleChangeQuestionAnswer("yes", 1)}
      >
        <StyledBackgroundBtnImg src={buttonImg} alt="yes button" />
        <StyledQuestionActionBtnText>Love It</StyledQuestionActionBtnText>
        <StyledBtnEmojiImg src={yesEmoji} alt="yes emoji" />
      </StyledQuestionActionBtn>
      <StyledQuestionActionBtn
        onClick={() => handleChangeQuestionAnswer("sometimes", 0.5)}
      >
        <StyledBackgroundBtnImg src={buttonImg} alt="somtimes button" />
        <StyledQuestionActionBtnText>Like it</StyledQuestionActionBtnText>
        <StyledBtnEmojiImg src={sometimesEmoji} alt="somtimes emoji" />
      </StyledQuestionActionBtn>
      <StyledQuestionActionBtn
        onClick={() => handleChangeQuestionAnswer("no", 0)}
      >
        <StyledBackgroundBtnImg src={buttonImg} alt="no button" />
        <StyledQuestionActionBtnText>Don’t Like it</StyledQuestionActionBtnText>
        <StyledBtnEmojiImg src={noEmoji} alt="no emoji" />
      </StyledQuestionActionBtn>
    </StyledQuestionActionsWrapper>

    <StyledAnswersScoreWrapper>
      <StyledScoreImg src={trophy} alt="trophy" score={score} />
      <StyledScoreBarWrapper>
        <StyledScoreBar width={`${score}%`} />
      </StyledScoreBarWrapper>
    </StyledAnswersScoreWrapper>

    <StyledCharacterImg src={currentQuestion.characterImg} alt="charcter" />
  </MainLayout>
);

Question.propTypes = {
  score: PropTypes.number,
  handleGoBackClick: PropTypes.func,
  handleChangeQuestionAnswer: PropTypes.func,
  currentQuestion: PropTypes.object
};

export default Question;
