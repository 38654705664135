import { RIASEC_CATEGORIES } from "./data";

const groupByQuestionType = questions => {
  return questions.reduce(function(acc, obj) {
    var key = obj["type"];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
};

export const results = (questions) => {
  const results = [];
  const groupedQuestionsObjectByType = groupByQuestionType(questions);

  for (const [key, value] of Object.entries(RIASEC_CATEGORIES)) {
    const questions = groupedQuestionsObjectByType[key];
    const scoreSum = questions.reduce(function(accumulator, currentQuestion) {
      return accumulator + currentQuestion.score;
    }, 0);

    const scorePercentage = (scoreSum / questions.length) * 100;

    const result = {
      ...value,
      questions,
      scorePercentage
    };
    results.push(result);
  }

  return results;
};
