import React, { useState } from "react";
import PropTypes from "prop-types";
import Camera from 'react-html5-camera-photo';
import styled from "@emotion/styled";

import MainLayout from "../layout";
import cameraImg from "../assets/images/photo-camera.svg";
import buttonImg from "../assets/images/button.png";

import 'react-html5-camera-photo/build/css/index.css';

const StyledTakePhotoBtn = styled.label({
  backgroundImage: `url(${buttonImg})`,
  height: 94,
  width: 230,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexFlow: "column",
  backgroundSize: "cover",
  color: "#ffffff",
  fontSize: 18,
  fontWeight: 600,
  letterSpacing: -0.63,
  lineHeight: "24px",
  textAlign: "center",
  marginTop: 40,
  img: {
    height: 25,
    width: 30,
    marginBottom: 3
  }
});

const StyledGoBackBtn = styled.div({
  marginTop: 47,
  marginBottom: 10,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexFlow: "column",

  ".arrow-up": {
    width: 0,
    height: 0,
    borderLeft: "9px solid transparent",
    borderRight: "9px solid transparent",
    borderBottom: "9px solid #2244a7",
    marginBottom: 7
  },

  ".text": {
    fontSize: 28,
    lineHeight: "36px",
    letterSpacing: -0.98,
    textAlign: "center",
    color: "#2244a7"
  }
});

const StyledTakePhotoTitle = styled.h2({
  color: "#011433",
  fontSize: 32,
  fontWeight: "bold",
  letterSpacing: -1.12,
  lineHeight: "42px",
  textAlign: "center",
  marginTop: 39
});

const TakePhoto = ({ handleGoBackClick, handleImageChange }) => {
  const [takePhoto, setTakePhoto] = useState(false);

  return (
    <MainLayout>
      <StyledGoBackBtn onClick={handleGoBackClick}>
        <span className="arrow-up"></span>
        <span className="text">BACK</span>
      </StyledGoBackBtn>

      <StyledTakePhotoTitle>
        Finally, let’s take <br />a beautiful photo for your ID
      </StyledTakePhotoTitle>

      <StyledTakePhotoBtn onClick={() => setTakePhoto(true)}>
        <img src={cameraImg} alt="kid" /> Open Camera
      </StyledTakePhotoBtn>
      
      {takePhoto && (
        <div style={{position: "absolute"}}>
          <Camera
            onTakePhoto = { (dataUri) => { handleImageChange(dataUri); } }
          />
        </div>
      )}
    </MainLayout>
  )
  
  };

TakePhoto.propTypes = {
  handleGoBackClick: PropTypes.func,
  handleImageChange: PropTypes.func
};

export default TakePhoto;
