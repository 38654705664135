import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

import MainLayout from "../layout";
import buttonImg from "../assets/images/button.png";

const StyledGoBackBtn = styled.div({
  marginTop: 64,
  marginBottom: 73,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexFlow: "column",

  ".arrow-up": {
    width: 0,
    height: 0,
    borderLeft: "9px solid transparent",
    borderRight: "9px solid transparent",

    borderBottom: "9px solid #2244a7",
    marginBottom: 7
  },

  ".text": {
    fontSize: 28,
    lineHeight: "36px",
    letterSpacing: -0.98,
    textAlign: "center",
    color: "#2244a7"
  }
});

const StyledProfileInfoEntryTitle = styled.h1({
  fontSize: 32,
  textAlign: "center",
  color: "#011433",
  marginBottom: 34,
  fontWeight: "bold",
  letterSpacing: -1.12,
  lineHeight: "42px"
});

const StyledProfileInfoEntryInput = styled.input({
  width: 365.7,
  border: "none",
  borderBottom: "solid 1px #afafaf",
  height: 40,
  outline: 0,
  marginBottom: 86.17,
  fontSize: 20,
  letterSpacing: -0.7,
  lineHeight: "26px",
  fontWeight: 600,
  textAlign: "center",
  color: "#2244a7",

  "&:focus": {
    borderColor: "#3056b1"
  },

  "::placeholder": {
    textAlign: "center",
    fontSize: 20,
    letterApacing: -0.7,
    lineHeight: "26px",
    fontWeight: 600,
    color: "#afafaf"
  }
});

const StyledGoNextBtn = styled.div({
  backgroundImage: `url(${buttonImg})`,
  width: 262,
  height: 105,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexFlow: "column",

  span: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: "26px",
    letterSpacing: -0.7,
    textAlign: "center",
    color: "#ffffff"
  },
  ".arrow-down": {
    width: 0,
    height: 0,
    borderLeft: "10px solid transparent",
    borderRight: "10px solid transparent",

    borderTop: "10px solid #fff",
    marginTop: 8
  }
});

const ProfileBasicInfoEntry = ({
  navigateToBack,
  navigateToNext,
  currentQuestion,
  handleChangeProfileQuestionAnswer
}) => (
  <MainLayout>
    <StyledGoBackBtn onClick={navigateToBack}>
      <span className="arrow-up"></span>
      <span className="text">BACK</span>
    </StyledGoBackBtn>

    <StyledProfileInfoEntryTitle className="name-entry-title">
      {currentQuestion.question}
    </StyledProfileInfoEntryTitle>

    <StyledProfileInfoEntryInput
      value={currentQuestion.answer}
      onChange={e => handleChangeProfileQuestionAnswer(e.target.value)}
      placeholder={currentQuestion.placeholder}
    />

    <StyledGoNextBtn onClick={navigateToNext}>
      <span>next</span>
      <span className="arrow-down"></span>
    </StyledGoNextBtn>
  </MainLayout>
);

ProfileBasicInfoEntry.propTypes = {
  navigateToBack: PropTypes.func,
  navigateToNext: PropTypes.func,
  currentQuestion: PropTypes.object,
  handleChangeProfileQuestionAnswer: PropTypes.func
};

export default ProfileBasicInfoEntry;
