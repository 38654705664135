import React from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

import MainLayout from "./layout";
import notFoundImg from "./assets/images/c4.png";
import buttonImg from "./assets/images/button.png";

const StyledNotFoundText = styled.h2({
  color: "#031631",
  fontSize: 28,
  fontWeight: "bold",
  letterSpacing: -0.98,
  lineHeight: "36px",
  marginTop: 24
});

const StyledNotFoundImg = styled.img({
  maxWidth: 140,
  maxHeight: 140,
  marginTop: 100
});

const StyledNextButton = styled(Link)({
  marginTop: 50,
  textDecoration: "none",
  backgroundImage: `url(${buttonImg})`,
  width: 262,
  height: 105,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexFlow: "column",

  span: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: "26px",
    letterSpacing: -0.7,
    textAlign: "center",
    color: "#ffffff"
  },
  ".arrow-down": {
    width: 0,
    height: 0,
    borderLeft: "10px solid transparent",
    borderRight: "10px solid transparent",

    borderTop: "10px solid #fff",
    marginTop: 8
  }
});

const NotFoundPage = () => (
  <MainLayout>
    <StyledNotFoundImg src={notFoundImg} alt="not found" />
    <StyledNotFoundText>Sorry! Page Not Found ;(</StyledNotFoundText>
    <StyledNextButton to="/">
      <span>Go To Home Page</span>
      <span className="arrow-down"></span>
    </StyledNextButton>
  </MainLayout>
);

export default NotFoundPage;
