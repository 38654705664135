import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import axios from 'axios';
import ReactExport from 'react-export-excel';

import wuzzufLogo from '../assets/images/logo.svg';

import ProfileCard from '../components/profile-card';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const StyledHeader = styled.header({
    padding: '37px 41px 0 41px',
    display: 'flex',
    justifyContent: 'space-between'
});

const StyledMain = styled.main({
    padding: 24,
    margin: '12px auto',
    maxWidth: 846,
    position: 'relative',
    display: 'flex',
    flexFlow: 'column',
    background: 'white',
    borderRadius: 5,
    boxShadow: '0 12px 24px 10px rgba(0,82,204,0.07)'
});

const StyledLoading = styled.span({
    textAlign: 'center',
    fontSize: 20
});

const StyledError = styled.span({
    color: '#92083d',
    textAlign: 'center',
    fontSize: 20
});

const Profiles = () => {
    const [profiles, setProfiles] = useState([]);
    const [error, setError] = useState('');
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        axios
            .get('/surveys')
            .then((res) => {
                setLoading(false);
                setProfiles(res.data);
            })
            .catch((err) => {
                setLoading(false);
                setError("Ooops, there's something wrong happened");
            });
    }, []);

    return (
        <div>
            <StyledHeader>
                <img src={wuzzufLogo} alt="logo" />

                {profiles.length > 0 && (
                    <ExcelFile element={<button>Download Excel</button>}>
                        <ExcelSheet data={profiles} name="Employees">
                            <ExcelColumn label="Name" value="kidName" />
                            <ExcelColumn label="Age" value="kidAge" />
                            <ExcelColumn label="School" value="kidSchool" />
                            <ExcelColumn label="Grade" value="kidGrade" />
                        </ExcelSheet>
                    </ExcelFile>
                )}
            </StyledHeader>

            <StyledMain>
                <>
                    {isLoading && <StyledLoading>Loading....</StyledLoading>}
                    {profiles.length > 0 &&
                        profiles.map((profile) => <ProfileCard key={profile.id} profile={profile} />)}
                    {error && <StyledError>{error}</StyledError>}
                </>
            </StyledMain>
        </div>
    );
};

export default Profiles;
