import React from "react";
import styled from "@emotion/styled";

import wuzzufLogo from "../assets/images/logo.svg";
import trophy from "../assets/images/trophy.png";
import yesEmoji from "../assets/images/yes-emoji.png";
import sometimesEmoji from "../assets/images/sometimes-emoji.png";
import noEmoji from "../assets/images/no-emoji.png";

import ProfileResults from "./profile-results";
import {results} from "../helper";

const StyledHeader = styled.header({
  display: "none",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "24px 16px",
  "@media only print": {
    display: "flex"
  }
});

const StyledTitle = styled.h1({
  color: "#011433",
  fontSize: 21,
  fontWeight: 600,
  lineHeight: "27px"
});

const StyledProfileBasicInfo = styled.div({
  display: "flex",
  alignItems: "center",
  padding: "0 16px",
  marginBottom: 14
});

const StyledProfileBasicData = styled.div({
  borderRadius: 22.99,
  backgroundColor: "#2196F3",
  flex: 1,
  WebkitPrintColorAdjust: "exact",
  padding: 16,
  position: "relative",
  display: "flex",
  flexFlow: "column",
  justifyContent: "center",
  color: "white"
});

const StyledProfileImage = styled.img({
  height: 88,
  width: 88,
  borderRadius: "50%",
  marginRight: 8
});

const StyledTrophyImg = styled.img({
  position: "absolute",
  height: 48,
  width: 48,
  right: 16
});

const StyledName = styled.h2({
  fontSize: 16,
  fontWeight: "bold",
  letterSpacing: -0.56,
  lineHeight: "21px"
});

const StyledSecondaryInfo = styled.span({
  opacity: 0.8,
  fontSize: 14,
  fontWeight: 500,
  lineHeight: "19px"
});

const StyledDescription = styled.p({
  color: "#011433",
  fontSize: 16,
  fontWeight: 500,
  lineHeight: "24px",
  margin: "0 16px 16px",
  "&:last-of-type": {
    marginBottom: 0
  },

  "@media only print": {
    fontsize: 14,
    lineHeight: "19px",
    "&:last-of-type": {
      paddingBottom: 32,
      borderBottom: "1px solid #E3E3E3"
    }
  }
});

const StyledProfileHeader = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  height: 93,
  padding: "0 40px",

  "@media only print": {
    display: "none"
  }
});

const StyledPrintBtn = styled.button({
  height: 48,
  width: 115,
  borderRadius: 24,
  background: "#0B58DA",
  marginRight: 24,
  border: 0,
  outline: 0,
  color: "#FFFFFF",
  fontSize: 16,
  fontWeight: 600,
  lineHeight: "21px"
});

const StyledStartNewTestBtn = styled.button({
  height: 49,
  width: 155,
  border: "1px solid #0B58DA",
  borderRadius: 24,
  background: "#ffffff",
  color: "#0B58DA",
  fontSize: 16,
  fontWeight: 600,
  lineHeight: "21px",
  outline: 0
});

const StyledProfileContent = styled.div({
  maxWidth: 775,
  margin: "48px auto",
  "@media only print": {
    maxWidth: "initial",
    margin: "initial"
  }
});

const StyledProfileBasicInfoWrapper = styled.div({
  padding: "48px 32px 32px",
  borderRadius: 40,
  boxShadow: "0 12px 24px 10px rgba(0,82,204,0.07)",
  background: "white",
  "@media only print": {
    padding: 0,
    borderRadius: 0,
    boxShadow: "none"
  }
});

const StyledProfileResultsWrapper = styled.div({
  padding: 48,
  borderRadius: 40,
  boxShadow: "0 12px 24px 10px rgba(0,82,204,0.07)",
  marginTop: 40,
  background: "white",

  "@media only print": {
    padding: 16,
    borderRadius: 0,
    boxShadow: "none",
    marginTop: 0
  }
});

const StyledProfileResultsHeaderWrapper = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  background: "white"
});

const StyledProfileResultsHeader = styled.h3({
  color: "#011433",
  fontSize: 36,
  lineHeight: "47px",
  fontWeight: 600,

  "@media only print": {
    fontSize: 21,
    lineHeight: "27px"
  }
});

const StyledHeaderIconsDefinition = styled.div({
  display: "flex",
  alignItems: "center",
  borderRadius: 19,
  background: "rgba(0,0,0,0.03)",
  padding: "8px 16px",
  img: {
    maxWidth: 24,
    maxHeight: 24,
    margin: "0 8px"
  },
  span: {
    color: "#000000",
    fontSize: 12.8,
    lineHeight: "18px"
  },
  "@media only print": {
    img: {
      maxWidth: 18,
      maxHeight: 18
    },
    span: {
      fontSize: 12,
      lineHeight: "16px"
    }
  }
});

const Profile = ({
  kidImage,
  kidName,
  kidAge,
  kidSchool,
  questions,
  handleStartNewTest
}) => {
  const handlePrint = () => {
    window.print();
  };

  return (
    <div>
      <StyledProfileHeader>
        <img src={wuzzufLogo} alt="wuzzuf logo" />
        <div>
          <StyledPrintBtn onClick={handlePrint}>Print</StyledPrintBtn>
          <StyledStartNewTestBtn onClick={handleStartNewTest}>
            Start New Test
          </StyledStartNewTestBtn>
        </div>
      </StyledProfileHeader>
      <StyledHeader>
        <StyledTitle>RIASEC Profile</StyledTitle>
        <img src={wuzzufLogo} alt="logo" />
      </StyledHeader>
      <StyledProfileContent>
        <StyledProfileBasicInfoWrapper>
          <StyledProfileBasicInfo>
            <StyledProfileImage src={kidImage} alt="user" />
            <StyledProfileBasicData>
              <StyledName>{kidName}</StyledName>
              <StyledSecondaryInfo>{kidAge} years old</StyledSecondaryInfo>
              <StyledSecondaryInfo>{kidSchool}</StyledSecondaryInfo>
              <StyledTrophyImg src={trophy} alt="trophy" />
            </StyledProfileBasicData>
          </StyledProfileBasicInfo>

          <StyledDescription>
            {kidName} is a hero! He finished a personality assessment in the
            WUZZUF recruitment center that evaluates his career interests when
            he grows up.
          </StyledDescription>

          <StyledDescription>
            The assessment is based on RIASEC which categorizes people into 6
            major types; Realistic, Investigative, Artistic, Social,
            Enterprising and Conventional.
          </StyledDescription>
        </StyledProfileBasicInfoWrapper>

        <StyledProfileResultsWrapper>
          <StyledProfileResultsHeaderWrapper>
            <StyledProfileResultsHeader>Results</StyledProfileResultsHeader>
            <StyledHeaderIconsDefinition>
              <img src={yesEmoji} alt="love it" />
              <span>Love it</span>
              <img src={sometimesEmoji} alt="like it" />
              <span>Like it</span>
              <img src={noEmoji} alt="hate it" />
              <span>Hate it</span>
            </StyledHeaderIconsDefinition>
          </StyledProfileResultsHeaderWrapper>
          <ProfileResults results={results(questions)} />
        </StyledProfileResultsWrapper>
      </StyledProfileContent>
    </div>
  );
};

export default Profile;
