import React, { useState, useRef } from 'react';

import Profiles from './components/profiles';
import Login from './components/login';

const AdminPage = () => {
    const [pageType, setPageType] = useState('login');
    const isAuthenticatedAdmin = useRef(JSON.parse(localStorage.getItem('isAuthenticatedAdmin')));

    if (isAuthenticatedAdmin.current || pageType === 'profiles') {
        return <Profiles />;
    }

    return <Login setPageType={setPageType} />;
};

export default AdminPage;
