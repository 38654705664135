import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

import wuzzufLogo from "./assets/images/logo.svg";
import topImage from "./assets/images/top.svg";
import rightImage from "./assets/images/right.svg";
import leftImage from "./assets/images/left.svg";
import kidsImage from "./assets/images/kids.svg";

const StyledHeader = styled.header({
  padding: "37px 0 0 41px"
});

const StyledMain = styled.main({
  padding: "37px 0 0",
  margin: "12px auto",
  maxWidth: 846,
  position: "relative",
  display: "flex",
  alignItems: "center",
  flexFlow: "column"
});

const StyledTopImgWrapper = styled.div({
  position: "absolute",
  top: 0,
  left: 0,
  display: "flex",
  justifyContent: "center",
  width: "100%",

  img: {
    width: 295,
    height: 74
  }
});

const StyledRightImgWrapper = styled.div({
  position: "absolute",
  top: 102,
  right: -12,

  img: {
    width: 85,
    height: 161
  }
});

const StyledLeftImgWrapper = styled.div({
  position: "absolute",
  top: 82,
  left: 2,

  img: {
    width: 57,
    height: 197
  }
});

const ContentCard = styled.div({
  width: 775,
  height: 550,
  borderRadius: 40,
  boxShadow: "0 12px 24px 10px rgba(0, 82, 204, 0.07)",
  background: "white",
  margin: "auto",
  marginBottom: 138,
  display: "flex",
  alignItems: "center",
  flexFlow: "column",
  padding: "0 64px"
});

const StyledKidsImg = styled.img({
  width: 428,
  height: 113,
  position: "absolute",
  bottom: 0
});

const MainLayout = ({ children, showKidsFooterImage }) => (
  <div>
    <StyledHeader>
      <img src={wuzzufLogo} alt="logo" />
    </StyledHeader>
    <StyledMain>
      <StyledTopImgWrapper>
        <img src={topImage} alt="top" />
      </StyledTopImgWrapper>

      <StyledRightImgWrapper>
        <img src={rightImage} alt="right" />
      </StyledRightImgWrapper>

      <StyledLeftImgWrapper>
        <img src={leftImage} alt="left" />
      </StyledLeftImgWrapper>

      <ContentCard>{children}</ContentCard>

      {showKidsFooterImage && <StyledKidsImg src={kidsImage} alt="kids" />}
    </StyledMain>
  </div>
);

MainLayout.propTypes = {
  children: PropTypes.any,
  showKidsFooterImage: PropTypes.bool
};

export default MainLayout;
