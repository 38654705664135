import React from "react";
import PropTypes from "prop-types";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import "./App.scss";

import Admin from "./admin-page";
import MainPage from "./main-page";
import NotFoundPage from "./not-found-page";

const App = ({ questions, profileBasicInfoQuestions }) => (
  <Router>
    <Switch>
      <Route exact path="/">
        <MainPage
          questions={questions}
          profileBasicInfoQuestions={profileBasicInfoQuestions}
        />
      </Route>
      <Route exact path="/admin">
        <Admin />
      </Route>
      <Route>
        <NotFoundPage />
      </Route>
    </Switch>
  </Router>
);

App.propTypes = {
  questions: PropTypes.array,
  profileBasicInfoQuestions: PropTypes.array
};

export default App;
