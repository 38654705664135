import React from "react";
import styled from "@emotion/styled";

const StyledProgressBar = styled.div(
  {
    width: 500,
    height: 16,
    background: "#eeeeee",
    WebkitPrintColorAdjust: "exact",
    borderRadius: 12
  },
  ({ width }) => ({
    width
  })
);

const StyledProgress = styled.div(
  {
    background: "#43A047",
    height: 16,
    borderRadius: 12
  },
  ({ width }) => ({
    width: `${width}%`
  })
);

const ProgressBar = ({ width, progressPercentage }) => {
  return (
    <StyledProgressBar width={width}>
      <StyledProgress width={progressPercentage} />
    </StyledProgressBar>
  );
};

export default ProgressBar;
