import React, { Fragment } from "react";
import styled from "@emotion/styled";

import yesEmoji from "../assets/images/yes-emoji.png";
import sometimesEmoji from "../assets/images/sometimes-emoji.png";
import noEmoji from "../assets/images/no-emoji.png";

import ProgressBar from "./progress-bar";

const StyledResultCard = styled.div({
  padding: "40px 0",
  "@media only print": {
    padding: "24px 0"
  },
  "&:last-of-type": {
    paddingBottom: 0
  }
});

const StyledBadgeAndDescriptionAndRatingWrapper = styled.div({
  display: "flex",
  alignItems: "flex-start",
  img: {
    height: 80,
    width: 68,
    minWidth: 68,
    marginRight: 16,
    "@media only print": {
      height: 61,
      width: 52
    }
  },

  p: {
    color: "rgba(1,20,51,0.87)",
    fontSize: 16,
    fontWeight: 600,
    lineHeight: "21px",
    "@media only print": {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: "19px"
    }
  }
});

const StyledQuestionsWithAnswers = styled.div({
  marginTop: 8,
  display: "flex",
  flexWrap: "wrap"
});

const StyledDivider = styled.div({
  width: 511,
  border: "1px solid #E3E3E3",
  margin: "0 auto"
});

const StyledRatingProgressWrapper = styled.div({
  display: "flex",
  alignItems: "center",
  marginTop: 12,
  strong: {
    color: "#757575",
    fontSize: 16,
    lineHeight: "21px",
    fontWeight: 600,
    marginRight: 4,

    "@media only print": {
      fontSize: 12.26,
      lineHeight: "16px"
    }
  }
});

const StyledQuestionWithIcon = styled.div({
  marginTop: 8,
  padding: "6px 16px",
  span: {
    color: "#011433",
    fontSize: 14,
    fontWeight: 600,
    lineHeight: "19px",
    marginRight: 8
  },
  img: {
    maxHeight: 20,
    maxWidth: 20,
    verticalAlign: "top"
  }
});

const ProfileResults = ({ results }) => {
  const questionIconByScore = score => {
    if (score === 1) {
      return yesEmoji;
    } else if (score === 0.5) {
      return sometimesEmoji;
    }
    return noEmoji;
  };

  return (
      results.map((result, index) => {
        return (
          <Fragment key={index}>
            <StyledResultCard>
              <StyledBadgeAndDescriptionAndRatingWrapper>
                <img src={result.image} alt="badge logo" />
                <div>
                  <p>{result.description}</p>
                  <StyledRatingProgressWrapper>
                    <strong>Rating</strong>
                    <ProgressBar
                      width={530}
                      progressPercentage={result.scorePercentage}
                    />
                  </StyledRatingProgressWrapper>
                </div>
              </StyledBadgeAndDescriptionAndRatingWrapper>
              <StyledQuestionsWithAnswers>
                {result.questions.map((question, index) => {
                  return (
                    <StyledQuestionWithIcon key={index}>
                      <span>{question.shortTitle}</span>
                      <img
                        src={questionIconByScore(question.score)}
                        alt="answer icon"
                      />
                    </StyledQuestionWithIcon>
                  );
                })}
              </StyledQuestionsWithAnswers>
            </StyledResultCard>
            {index !== results.length - 1 && <StyledDivider />}
          </Fragment>
        );
      })
  );
};

export default ProfileResults;
