import puzzleImage from "./assets/images/puzzle.svg";
import aImg from "./assets/images/a.svg";
import rImg from "./assets/images/r.svg";
import cImg from "./assets/images/c.svg";
import eImg from "./assets/images/e.svg";
import sImg from "./assets/images/s.svg";
import iImg from "./assets/images/i.svg";
import c1Img from "./assets/images/c1.png";
import c2Img from "./assets/images/c2.png";
import c3Img from "./assets/images/c3.png";
import c4Img from "./assets/images/c4.png";
import c5Img from "./assets/images/c5.png";
import c6Img from "./assets/images/c6.png";
import c7Img from "./assets/images/c7.png";
import c8Img from "./assets/images/c8.png";
import c9Img from "./assets/images/c9.png";
import c10Img from "./assets/images/c10.png";

export const questions = [
  {
    title: "Do you like solving puzzles?",
    image: puzzleImage,
    answer: "",
    score: null,
    type: "R",
    characterImg: c1Img,
    shortTitle: "Solving puzzles?"
  },
  {
    title: "Do you like playing with legos?",
    image: puzzleImage,
    answer: "",
    score: null,
    type: "R",
    characterImg: c2Img,
    shortTitle: "Playing with legos?"
  },
  {
    title: "Do you like science?",
    image: puzzleImage,
    answer: "",
    score: null,
    type: "I",
    characterImg: c3Img,
    shortTitle: "Like science?"
  },
  {
    title: "Do you like taking photos of your friends, family or nature?",
    image: puzzleImage,
    answer: "",
    score: null,
    type: "A",
    characterImg: c4Img,
    shortTitle: "Taking photos of your friends, family or nature?"
  },
  {
    title: "Would you like to be a teacher one day?",
    image: puzzleImage,
    answer: "",
    score: null,
    type: "S",
    characterImg: c5Img,
    shortTitle: "Like to be a teacher one day?"
  },
  {
    title: "Do you enjoy organizing your room yourself?",
    image: puzzleImage,
    answer: "",
    score: null,
    type: "C",
    characterImg: c6Img,
    shortTitle: "Organizing your room yourself?"
  },
  {
    title: "Do you like repairing your toys yourself?",
    image: puzzleImage,
    answer: "",
    score: null,
    type: "R",
    characterImg: c7Img,
    shortTitle: "Repairing your toys yourself?"
  },
  {
    title: "Would you like to sell your toys to your friends?",
    image: puzzleImage,
    answer: "",
    score: null,
    type: "E",
    characterImg: c8Img,
    shortTitle: "Selling your toys to your friends?"
  },
  {
    title: "Do you like knowing how your toys work?",
    image: puzzleImage,
    answer: "",
    score: null,
    type: "I",
    characterImg: c9Img,
    shortTitle: "Knowing how your toys work?"
  },
  {
    title:
      "If something was lost at home, would like to be the one to search for it and find it ?",
    image: puzzleImage,
    answer: "",
    score: null,
    type: "I",
    characterImg: c10Img,
    shortTitle: "Searching for something lost?"
  },
  {
    title: "Do you like playing musical instruments (Piano, guitar, etc)?",
    image: puzzleImage,
    answer: "",
    score: null,
    type: "A",
    characterImg: c1Img,
    shortTitle: "Playing musical instruments?"
  },
  {
    title: "Would you like to be a movie actor?",
    image: puzzleImage,
    answer: "",
    score: null,
    type: "A",
    characterImg: c2Img,
    shortTitle: "Like to be a movie actor?"
  },
  {
    title: "Do you like to share your toys with your friends?",
    image: puzzleImage,
    answer: "",
    score: null,
    type: "S",
    characterImg: c3Img,
    shortTitle: "Share your toys with your friends?"
  },
  {
    title: "Would you like to be a school principal one day?",
    image: puzzleImage,
    answer: "",
    score: null,
    type: "E",
    characterImg: c4Img,
    shortTitle: "Being a school principal one day?"
  },
  {
    title: "Do you ever get detention at school?",
    image: puzzleImage,
    answer: "",
    score: null,
    type: "C",
    characterImg: c5Img,
    shortTitle: "Get detention at school?"
  },
  {
    title: "Would you like to know more about the stars and the planets?",
    image: puzzleImage,
    answer: "",
    score: null,
    type: "I",
    characterImg: c6Img,
    shortTitle: "Know more about the stars and the planets?"
  },
  {
    title: "Do you like drawing pictures?",
    image: puzzleImage,
    answer: "",
    score: null,
    type: "A",
    characterImg: c7Img,
    shortTitle: "Drawing pictures?"
  },
  {
    title: "Do you like helping your grandparents do things?",
    image: puzzleImage,
    answer: "",
    score: null,
    type: "S",
    characterImg: c8Img,
    shortTitle: " Helping your grandparents do things?"
  },
  {
    title: "Would you like to represent your classmates?",
    image: puzzleImage,
    answer: "",
    score: null,
    type: "E",
    characterImg: c9Img,
    shortTitle: "Representing your classmates?"
  },
  {
    title:
      "Do you like collecting things (action figures, coins, stamps, etc)?",
    image: puzzleImage,
    answer: "",
    score: null,
    type: "C",
    characterImg: c10Img,
    shortTitle: "Collecting things?"
  },
  {
    title: "Is math your favorite subject?",
    image: puzzleImage,
    answer: "",
    score: null,
    type: "C",
    characterImg: c1Img,
    shortTitle: "Is math your favorite subject?"
  },
  {
    title: "Do you like taking care of plants or pets?",
    image: puzzleImage,
    answer: "",
    score: null,
    type: "S",
    characterImg: c2Img,
    shortTitle: "Taking care of plants or pets?"
  },
  {
    title: "Would you like to participate in your school’s morning broadcast?",
    image: puzzleImage,
    answer: "",
    score: null,
    type: "E",
    characterImg: c3Img,
    shortTitle: "Participate in your school’s morning broadcast?"
  },
  {
    title: "Would you like to be a firefighter?",
    image: puzzleImage,
    answer: "",
    score: null,
    type: "R",
    characterImg: c4Img,
    shortTitle: "Like to be a firefighter?"
  },
  {
    title: "Do you like learning about plants or animals?",
    image: puzzleImage,
    answer: "",
    score: null,
    type: "I",
    characterImg: c5Img,
    shortTitle: "Learning about plants or animals?"
  },
  {
    title: "Do you like taking care of your younger brother/sister?",
    image: puzzleImage,
    answer: "",
    score: null,
    type: "S",
    characterImg: c6Img,
    shortTitle: "Taking care of your younger brother/sister?"
  },
  {
    title: "Would you like to be famous?",
    image: puzzleImage,
    answer: "",
    score: null,
    type: "E",
    characterImg: c7Img,
    shortTitle: "Like to be famous?"
  },
  {
    title: "Do you like playing card games?",
    image: puzzleImage,
    answer: "",
    score: null,
    type: "C",
    characterImg: c8Img,
    shortTitle: "Playing card games?"
  }
];

export const profileBasicInfoQuestions = [
  {
    key: "kidName",
    question: "What is your name?",
    answer: "",
    placeholder: "Type your name here"
  },
  {
    key: "kidAge",
    question: "How old are you?",
    answer: "",
    placeholder: "Type your age here"
  },
  {
    key: "kidSchool",
    question: "What School do You go to?",
    answer: "",
    placeholder: "Type your school name here"
  },
  {
    key: "kidGrade",
    question: "What is your grade?",
    answer: "",
    placeholder: "Type your grade here"
  }
];

export const RIASEC_CATEGORIES = {
  R: {
    image: rImg,
    description:
      "Realistic people are ones that have athletic ability, prefer to work with objects, machines, tools, plants or animals, or to be outdoors."
  },
  I: {
    image: iImg,
    description:
      "Investigative people are ones that like to observe, learn, investigate, analyze, evaluate, or solve problems."
  },
  A: {
    image: aImg,
    description:
      "Artistic people are ones that have artistic, innovating, or intuitional abilities and like to work in unstructured situations using their imagination and creativity."
  },
  S: {
    image: sImg,
    description:
      "Social people are ones that like to work with people to enlighten, inform, help, train, or cure them, or are skilled with words."
  },
  E: {
    image: eImg,
    description:
      "Enterprising people are ones that like to work with people, influencing, persuading, leading or managing for organizational goals or economic gain."
  },
  C: {
    image: cImg,
    description:
      "Conventional people are ones that like to work with data, have clerical or numerical ability, carry out tasks in detail, process and order oriented or follow through on others’ instructions."
  }
};
